import './index.scss';
import { Link } from 'react-router-dom';
import LogoTitleJ from '../../assets/images/2.svg';
import LogoTitleP from '../../assets/images/p.svg';
import { useEffect, useState } from 'react';
import AnimatedLetters from '../AnimatedLetters/index.js';
import Logo from './Logo/index.js';
import Loader from 'react-loaders';

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    // const nameArray = ['u','a','n'];
    const nameArray = ['uan'];
    // const nameArray2 = ['a','b','l','o', ' ','C','o','r','t','é','s'];
    const nameArray2 = ['ablo', ' ', 'Cortés'];
    // const jobArray = ['F','r','o','n','t','e','n','d', ' ', 'd','e','v','e','l','o','p','e','r','.'];
    const jobArray = ['Frontend',' ', 'developer.']

    
    useEffect(() => {
        setTimeout(()=> {
            setLetterClass('text-animate-hover');
        }, 4000);
    }, []);
    

return (

    <>
    <div className='container home-page'>
        <div className='text-zone'>

        <h1>
                <span className={letterClass}>H</span>
                <span className={`${letterClass} _12`}>i,</span>
                <br/>
                <span className={`${letterClass} _13`}>I</span>
                <span className={`${letterClass} _14`}>'m</span>
                 <img 
                    src={LogoTitleJ} alt="logo-j"/>

                 <AnimatedLetters 
                 letterClass={letterClass}
                 strArray={nameArray}
                 idx={15}
                 />
                <img className={'logo-p'}
                    src={LogoTitleP} alt="logo-p"/>

                <AnimatedLetters 
                 letterClass={letterClass}
                 strArray={nameArray2}
                 idx={22}/><br/>

                <AnimatedLetters 
                letterClass={letterClass}
                 strArray={jobArray}
                idx={17}/>
        </h1>
        <h2>
            <Link to="/contact" className='flat-button'>CONTACT ME</Link>
        </h2>
        </div>
        <Logo/>
    </div>
    <Loader type="ball-zig-zag-deflect"/>
    </>

)
} 

export default Home;