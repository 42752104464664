import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import emailjs from '@emailjs/browser';
import AnimatedLetters from '../AnimatedLetters';
import Loader from 'react-loaders';
import './index.scss';

const Contact = () => {
    //init email js
    emailjs.init(`${process.env.REACT_APP_EMAILJS_PK}`);

        //states
    const [letterClass, setLetterClass] = useState('text-animate');
    const [loading, setLoading] = useState(false);
    const refForm = useRef();

    useEffect(() => {
        setTimeout(()=> {
            setLetterClass('text-animate-hover');
        }, 4000);
    },[]);
    
const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
        emailjs
        .sendForm(
            `${process.env.REACT_APP_EMAILJS_SERVICE}`,
            `${process.env.REACT_APP_EMAILJS_TEMPLATE}`,
                refForm.current,
            `${process.env.REACT_APP_EMAILJS_PK}`,
        )
        .then(
            () => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your message has been sent',
                    showConfirmButton: false,
                    timer: 1500
                  });
                window.location.reload(false);
            })
    } catch (error) {
        
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to send message, please try again',
          });
    } finally{

        setLoading(false);
    }

          
                
            
            
}
    return (
        <>
        <div className='container contact-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['C', 'O', 'N', 'T', 'A', 'C',
                        'T', ' ', 'M', 'E']}
                        idx={15}
                        />
                    </h1>
                    <p>
                    I'm looking for freelancing work, 
                    especially for challenging or big projects. 
                    However, if you have any more requests or inquiries, 
                    do get in touch with me using the form below.
                    </p>
                    <div className='contact-form'>
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className='half'>
                                    <input placeholder='Name' type='text' name='name' required />

                                </li>
                                <li className='half'>
                                    <input placeholder='Email' type='email' name='email' required />

                                </li>
                                <li>
                                    <input placeholder='Subject' type='text' name='subject' required />

                                </li>
                                <li>
                                    <textarea 
                                    placeholder='Message'
                                    name='message'
                                    required
                                    >
                                    </textarea>

                                </li>
                                <li>
                                    <input type='submit' className='flat-button' value={loading ? "SENDING..." :"SEND"} />

                                </li>
                            </ul>
                        </form>

                    </div>
                </div>
                <div className='info-map'>
                   
                    Bulgaria.

                </div>
                <div className='map-wrap'>
                <MapContainer center={[43.204666, 27.910543]} zoom={13}>
                    <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'/>
                    <Marker position={[43.204666, 27.910543]}>
                        <Popup>Juan lives here, come over for a cup of coffee 😀</Popup>
                    </Marker>

                </MapContainer>
                </div>
        </div>
        <Loader type="ball-zig-zag-deflect"/>
        </>
    )
}

export default Contact;