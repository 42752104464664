import { useState } from 'react';
import { useEffect } from 'react';
import AnimatedLetters from '../AnimatedLetters';
import portfolioData from '../../data/portfolio.json';
import Loader from 'react-loaders';
import './index.scss';


const Portfolio = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
  
    useEffect(() => {
        setTimeout(()=> {
            setLetterClass('text-animate-hover');
        }, 2000);
    }, []);
// console.log(portfolioData)
    const renderProjects = (portfolio) => {
        return(
            <div className='images-container'>
                {
                    portfolio.map((port) => {
                        return (
                            <div key={port.title}
                           
                            className='images-box'>
                            <div className='image-box-cover cover'>
                               <button className='button-github'
                                onClick={() => window.open(port.repository)}>
                                    View Code</button>
                                                               
                                    <button className='button-website'
                                        onClick={() => window.open(port.url)}>
                                            View Demo</button>

                            </div>
                            <img style={{background: 'black'}}
                            src={port.cover}
                            className='portfolio-image'
                            alt={port.title}
                            />
                            
                        </div>
                        );
                    })
                }

            </div>
        );
    }

    return(
        <>
        <div className='container projects-page'>
            <h1 className='page-title'>
            <AnimatedLetters
                    letterClass={letterClass}
                    strArray={['P', 'R', 'O', 'J', 'E', 'C', 'T', 'S']}
                    idx={15}
                    />
            </h1>
            <div>{renderProjects(portfolioData.portfolioArray)}</div>
        </div>
        <Loader type="ball-zig-zag-deflect"/>
        </>
    );
}

export default Portfolio;