import './index.scss';
import AnimatedLetters from '../AnimatedLetters/index.js'
import { useState } from 'react';
import { useEffect } from 'react';
import Loader from 'react-loaders';
import { 
    faReact,
    faCss3,
    faGitAlt,
    faHtml5,
    faJsSquare } from '@fortawesome/free-brands-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    
    
const  About = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    
    useEffect(() => {
        setTimeout(()=> {
            setLetterClass('text-animate-hover');
        }, 2000);
    }, []);

    return (
        <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                    letterClass={letterClass}
                    strArray={['A', 'B', 'O', 'U', 'T', ' ', 'M', 'E']}
                    idx={15}
                    />
                </h1>
                <p>
                As a frontend developer, I am highly motivated to succeed in this field and continuously learn and improve my skills. I possess a strong foundation in HTML, CSS, and JavaScript and remain committed to keeping up with the latest advancements and best practices. My ability to adapt quickly to new technologies and methodologies allows me to stay ahead of the curve.
                </p>
                <p>
                I am enthusiastic about the possibility of collaborating with your company and contributing to your success.
                </p>
            </div>

            <div className='stage-cube-cont'>
                <div className='cubespinner'>
                    <div className='face1'>
                        <FontAwesomeIcon icon={faHtml5} color="#C06529"/>
                    </div>
                    <div className='face2'>
                        <FontAwesomeIcon icon={faCss3} color="#28A4D9"/>
                    </div>
                    <div className='face3'>
                        <FontAwesomeIcon icon={faJsSquare} color="#EFD81D"/>
                    </div>
                    <div className='face4'>
                        <FontAwesomeIcon icon={faReact} color="#5ED4F4"/>
                    </div>
                    <div className='face5'>
                        <FontAwesomeIcon icon={faGitAlt} color="#EC4D28"/>
                    </div>
                    <div className='face6'>
                        {/* <FontAwesomeIcon icon={faGitAlt} color="#EC4D28"/> */}
                    </div>
                </div>
            </div>
        </div>
        <Loader type="ball-zig-zag-deflect"/>
        </>
        
    )
}

export default About;