import { Link, NavLink } from 'react-router-dom';
import './index.scss';
import LogoJP from '../../assets/images/jp.svg';
import LogoSubtittle from '../../assets/images/1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faSuitcase, faUser, faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useState } from 'react';




const Sidebar = () => {
    const [hideNavBar, showNavBar] = useState(false);
    

    return(

    <div className="nav-bar">
        <Link className="logo"  to="/">
            <img src={LogoJP} alt="logo"/>
            <img src={LogoSubtittle} alt="jpcortes"/>
        </Link>
        
        
        <nav className={hideNavBar ? 'hide': ''}>
            <NavLink onClick={() => showNavBar(false)}
            exact="true" activeclassname="active" to="/">
                <FontAwesomeIcon icon={faHome} color="#4d4d4e"/>
            </NavLink>
            <NavLink onClick={() => showNavBar(false)}
            exact="true" activeclassname="active" className="about-link" to="/about">
                <FontAwesomeIcon icon={faUser} color="#4d4d4e"/>
            </NavLink>
            <NavLink onClick={() => showNavBar(false)}
            exact="true" activeclassname="active" className="projects-link" to="/portfolio">
                <FontAwesomeIcon icon={faSuitcase} color="#4d4d4e"/>
            </NavLink>
            <NavLink onClick={() => showNavBar(false)}
            exact="true" activeclassname="active" className="contact-link" to="/contact">
                <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e"/>
            </NavLink>
        </nav>
        <ul>
            <li>
                <a className="anchor-icon" 
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/juan-pablo-cortes/"
                >
                    <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e"/>
                </a>
            </li>
            <li>
                <a className="anchor-icon"
                target="_blank"
                rel="noreferrer"
                href="https://github.com/jp-cortes"
                >
                    <FontAwesomeIcon icon={faGithub} color="#4d4d4e"/>
                </a>
            </li>
            {/* <li>
                <a className="anchor-icon"
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/jp_cortes_"
                >
                    <FontAwesomeIcon icon={faTwitter} color="#4d4d4e"/>
                </a>
            </li> */}
        </ul>
        <FontAwesomeIcon
        onClick={() => showNavBar(true)}
        icon={faBars}
        color="#fff"
        size='2x'
        className={hideNavBar ? 'hide' :'nav-bar__menu'}
        />
        <FontAwesomeIcon
        onClick={() => showNavBar(false)}
        icon={faClose}
        color="#fff"
        size='2x'
        className={hideNavBar? 'nav-bar__close': 'hide'}
        />

    </div>
    )

    }

export default Sidebar;